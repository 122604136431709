<template>
  <v-container fluid id="artistView" v-if="tattooer">
    <base-material-card>
      <v-row>
        <v-col cols="12" md="12" xl="12">
          <v-btn
            color="primary"
            @click.native="$router.back()"
            style="height: 30px"
            outlined
            v-if="!$vuetify.breakpoint.xsOnly"
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t("back") }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="5" class="py-0">
          <tattooer-card :tattooer="tattooer" class="tattooer mt-0">
          </tattooer-card>
        </v-col>

        <v-col cols="12" md="7" class="py-0">
          <expenses-table
            outlined
            ref="expensesTableTattooer"
            type="tattooer"
            :tattooer="tattooer"
            :title="$t('expenses.total_sum')"
            height="150px"
            class="mt-2"
          ></expenses-table>
        </v-col>
        <v-col cols="12" md="3" class="py-0">
          <v-card elevation="6" class="mt-0">
            <v-card-title style="justify-content: center">
              <h2
                class="my-3"
                style="text-transform: uppercase; font-weight: normal"
              >
                Estancias
              </h2>
            </v-card-title>
            <v-data-table
              id="tableEstancia"
              class="no_pagination"
              :headers="headersRelations"
              :items="relations"
              :loading="loading"
              disable-sort
              hide-default-header
              :items-per-page="3"
              mobile-breakpoint="0"
              dense
              :footer-props="{
                showFirstLastPage: false,
                'items-per-page-text': '',
                'items-per-page-options': [3, 5],
              }"
            >
              <template v-slot:item.to="{ item }">
                <div v-if="item.to == null">-</div>
                <div v-else>
                  {{ $d(new Date(item.to), "date2digits") }}
                </div>
              </template>
              <template v-slot:item.from="{ item }">
                <div>
                  {{ $d(new Date(item.from), "date2digits") }}
                </div>
              </template>
              <template v-slot:footer.page-text="{}"></template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col cols="12" md="5" class="py-0" v-if="inviteLink">
          <v-card elevation="6" class="mt-0">
            <v-card-title
              class="py-0"
              style="justify-content: center; text-align: center"
            >
              <h2 style="text-transform: uppercase; font-weight: normal">
                {{ $t("tattooers.invite_link") }}
              </h2>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row justify="center"
                ><v-col class="py-0">
                  <v-text-field
                    outlined
                    dense
                    id="tattooerURL"
                    readonly
                    v-model="inviteLink"
                  >
                    <template slot="prepend">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            icon
                            class="pa-0"
                            style="margin-top: -8px !important"
                            v-on="on"
                            @click="copy('tattooerURL')"
                          >
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("copy") }}</span>
                      </v-tooltip>
                    </template>
                  </v-text-field></v-col
                ></v-row
              >
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-tabs v-model="tab">
        <v-tab href="#appointment">{{ $tc("appointment", 2) }}</v-tab>
        <v-tab href="#box">{{ $tc("box", 2) }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="appointment">
          <base-data-table-cards
            :title="$tc('appointment', 2)"
            :footer-props="{
              'items-per-page-text': $vuetify.breakpoint.smAndDown
                ? ''
                : $t('items_per_page'),
            }"
            :headers="headers"
            :headers_small="headers_small"
            :data="items"
          >
            <template v-slot:item_customer="item"
              >{{ item.customer.user.first_name }}
              {{ item.customer.user.last_name }}</template
            >
            <template v-slot:item_tattooer="item"
              >{{ item.tattooer.user.first_name }}
              {{ item.tattooer.user.last_name }}</template
            >
            <template v-slot:item_studio="item"
              >{{ item.studio.user.first_name }}
              {{ item.studio.user.last_name }}</template
            >
            <template v-slot:item_date="item"
              ><span v-if="item.available_dates.length > 0">{{
                $browserDetect.isSafari || $browserDetect.meta.name === "Safari"
                  ? $d(
                      new Date(item.available_dates[0].date.substring(0, 10)),
                      "date2digits"
                    ) +
                    " " +
                    item.available_dates[0].date.substring(11, 16)
                  : $d(new Date(item.available_dates[0].date), "short")
              }}</span
              ><span v-else>-</span></template
            >
            <template v-slot:item_price="item">{{ finalPrice(item) }}</template>
            <template v-slot:item_actions="item"
              ><v-btn
                icon
                @click="
                  $router.push({
                    name: 'appointmentView',
                    params: { id: item.id },
                  })
                "
              >
                <v-icon small>$eye</v-icon></v-btn
              ></template
            >
          </base-data-table-cards>
        </v-tab-item>
        <v-tab-item value="box">
          <base-data-table-cards
            :footer-props="{
              'items-per-page-text': $vuetify.breakpoint.smAndDown
                ? ''
                : $t('items_per_page'),
            }"
            title="Boxs"
            :headers="headersBoxes"
            :headers_small="headersBoxes_small"
            :data="itemsBoxes"
          >
            <template v-slot:item_box="item">
              {{ item.boxes.order_by }} - {{ item.boxes.name }}
            </template>
            <template v-slot:item_date="item">
              <template v-if="$vuetify.breakpoint.smAndDown">
                <div>{{ $d(new Date(item.start_date), "date2digits") }}</div>

                <div>{{ item.start_time }} - {{ item.end_time }}</div>
              </template>
              <div v-else>
                {{ $d(new Date(item.start_date), "date2digits") }}

                {{ item.start_time }} - {{ item.end_time }}
              </div>
            </template>
            <template v-slot:item_actions="item">
              <v-btn
                icon
                @click="
                  $router.push({
                    name: 'boxList',
                    params: { btn: 'day', time: item.start_date },
                  })
                "
              >
                <v-icon small>$eye</v-icon>
              </v-btn>
            </template>
          </base-data-table-cards>
        </v-tab-item>
      </v-tabs-items>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "TattooerView",
  data() {
    return {
      tattooer: null,
      items: [],
      itemsBoxes: [],
      relations: [],
      options: {},
      optionsBox: {},
      totalPrice: 0,
      tab: "appointment",
    };
  },
  components: {
    TattooerCard: () => import("@/components/tattooer/Card"),
    ExpensesTable: () => import("@/components/expense/Table"),
  },
  watch: {
    options: {
      handler() {
        this.fetchAppointments();
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchTattooers();
    this.fetchAppointments();
    this.fetchAppointmentsTotalPriceByTattooerId();
    this.fetchTattooerStudioRelations();
    this.fetchBoxes();
  },
  methods: {
    ...mapActions("tattooers", ["getTattooer", "getTattooerStudioRelations"]),
    ...mapActions("boxes", ["getBoxesReservations"]),
    ...mapActions("appointments", [
      "getAppointments",
      "getAppointmentsByTattooerId",
    ]),

    fetchTattooers() {
      this.getTattooer(this.$route.params.id).then((tattooer) => {
        this.tattooer = tattooer;
        setTimeout(() => {
          this.$refs.expensesTableTattooer.fetchExpenses();
          this.$refs.expensesTableTattooer.fetchAmount();
        }, 1000);
      });
    },
    fetchAppointments() {
      this.loading = true;
      this.getAppointments({
        pagination: this.options,
        filters: {
          tattooer_id: this.$route.params.id,
          studio_id: this.$store.state.auth.user.studio.id,
        },
      }).then((appointments) => {
        this.items = appointments.data;
        this.totalAppointments = appointments.total;
        this.loading = false;
      });
    },
    fetchBoxes() {
      let filters = { tattooer_id: this.$route.params.id };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      this.getBoxesReservations({
        pagination: this.optionsBox,
        filters,
      }).then((x) => {
        this.itemsBoxes = x.data;
      });
    },
    fetchAppointmentsTotalPriceByTattooerId() {
      this.loading = true;
      //console.log("fetch");
      this.getAppointmentsByTattooerId({
        tattooer_id: this.$route.params.id,
      }).then((tattooerPrices) => {
        //console.log("inside fetch");
        this.appointmentsByTat = tattooerPrices;
        for (this.x = 0; this.x < this.appointmentsByTat.length; this.x++) {
          this.totalPrice += this.appointmentsByTat[this.x].price;
        }

        this.loading = false;
      });
    },
    fetchTattooerStudioRelations() {
      this.loading = true;
      //console.log("fetch");
      this.getTattooerStudioRelations({
        tattooer_id: this.$route.params.id,
        studio_id: this.$store.state.auth.user.id,
      }).then((tatStuRel) => {
        //console.log("inside fetchhh");
        this.relations = tatStuRel;
        //console.log(this.relations);
        this.loading = false;
      });
    },
    finalPrice(appoinment) {
      let price = 0;
      appoinment.available_dates.forEach((x) => {
        if (
          x.type === "appointment_day" ||
          x.type === "appointment_day_session"
        ) {
          price += x.sesion_price;
        }
      });
      if (price != 0) return this.$n(price, "currency");
      return "-";
    },
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("tattooerURL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.$alert(this.$t("link_copy_success"), "", "");
    },
  },
  computed: {
    totalPriceSice() {
      if (Math.abs(this.totalPrice) > 1000000000) {
        return "20px";
      } else if (Math.abs(this.totalPrice) > 1000000) {
        return "30px";
      } else if (Math.abs(this.totalPrice) > 10000) {
        return "40px";
      } else return "50px";
    },
    inviteLink() {
      let relations = this.relations.filter((x) => x.status != "archived");
      if (relations[0] && relations[0].hash)
        return `${window.location.origin}/?#/inscription/tattooer/${relations[0].tattooer_id}/studio/${relations[0].studio_id}/${relations[0].hash}`;

      return null;
    },
    headers() {
      return [
        {
          text: this.$tc("customer"),
          align: "center",
          sortable: false,
          value: "customer",
        },
        {
          text: this.$tc("artist"),
          align: "center",
          sortable: false,
          value: "tattooer",
        },
        {
          text: this.$t("appointments.price"),
          align: "center",
          sortable: false,
          value: "price",
        },
        {
          text: this.$t("appointments.date"),
          align: "center",
          sortable: false,
          value: "date",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
    },

    headers_small() {
      return [
        {
          text: this.$tc("customer"),
          align: "center",
          sortable: false,
          value: "customer",
        },
        {
          text: this.$t("appointments.price"),
          align: "center",
          sortable: false,
          value: "price",
        },
      ];
    },
    headersBoxes() {
      return [
        {
          text: this.$tc("box"),
          align: "center",
          sortable: false,
          value: "box",
        },
        {
          text: this.$t("appointments.date"),
          align: "center",
          sortable: false,
          value: "date",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ];
    },

    headersBoxes_small() {
      return [
        {
          text: this.$tc("box"),
          align: "center",
          sortable: false,
          value: "box",
          cols: "5",
        },
        {
          text: this.$t("appointments.date"),
          align: "center",
          sortable: false,
          value: "date",
          class: "py-0",
        },
      ];
    },

    headersRelations() {
      return [
        {
          text: this.$tc("from"),
          align: "center",
          sortable: false,
          value: "from",
        },
        {
          text: this.$tc("to"),
          align: "center",
          sortable: false,
          value: "to",
        },
      ];
    },
  },
};
</script>

<style lang="sass">
#artistView
  .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
    margin-top: 8px
  h2
    font-weight: normal
    font-size: 20px
  .v-data-table
    td
      padding: 3px 8px !important
  .tattooer
    .v-data-table
      td:first-child
        color: var(--v-gris1-base)
</style>
