var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tattooer)?_c('v-container',{attrs:{"fluid":"","id":"artistView"}},[_c('base-material-card',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","xl":"12"}},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticStyle:{"height":"30px"},attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.$t("back"))+" ")],1):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('tattooer-card',{staticClass:"tattooer mt-0",attrs:{"tattooer":_vm.tattooer}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"7"}},[_c('expenses-table',{ref:"expensesTableTattooer",staticClass:"mt-2",attrs:{"outlined":"","type":"tattooer","tattooer":_vm.tattooer,"title":_vm.$t('expenses.total_sum'),"height":"150px"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"mt-0",attrs:{"elevation":"6"}},[_c('v-card-title',{staticStyle:{"justify-content":"center"}},[_c('h2',{staticClass:"my-3",staticStyle:{"text-transform":"uppercase","font-weight":"normal"}},[_vm._v(" Estancias ")])]),_c('v-data-table',{staticClass:"no_pagination",attrs:{"id":"tableEstancia","headers":_vm.headersRelations,"items":_vm.relations,"loading":_vm.loading,"disable-sort":"","hide-default-header":"","items-per-page":3,"mobile-breakpoint":"0","dense":"","footer-props":{
              showFirstLastPage: false,
              'items-per-page-text': '',
              'items-per-page-options': [3, 5],
            }},scopedSlots:_vm._u([{key:"item.to",fn:function(ref){
            var item = ref.item;
return [(item.to == null)?_c('div',[_vm._v("-")]):_c('div',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.to), "date2digits"))+" ")])]}},{key:"item.from",fn:function(ref){
            var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.from), "date2digits"))+" ")])]}},{key:"footer.page-text",fn:function(ref){return undefined}}],null,false,448667357)})],1)],1),(_vm.inviteLink)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"5"}},[_c('v-card',{staticClass:"mt-0",attrs:{"elevation":"6"}},[_c('v-card-title',{staticClass:"py-0",staticStyle:{"justify-content":"center","text-align":"center"}},[_c('h2',{staticStyle:{"text-transform":"uppercase","font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm.$t("tattooers.invite_link"))+" ")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","id":"tattooerURL","readonly":""},model:{value:(_vm.inviteLink),callback:function ($$v) {_vm.inviteLink=$$v},expression:"inviteLink"}},[_c('template',{slot:"prepend"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pa-0",staticStyle:{"margin-top":"-8px !important"},attrs:{"icon":""},on:{"click":function($event){return _vm.copy('tattooerURL')}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,87977154)},[_c('span',[_vm._v(_vm._s(_vm.$t("copy")))])])],1)],2)],1)],1)],1)],1)],1):_vm._e()],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"href":"#appointment"}},[_vm._v(_vm._s(_vm.$tc("appointment", 2)))]),_c('v-tab',{attrs:{"href":"#box"}},[_vm._v(_vm._s(_vm.$tc("box", 2)))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"appointment"}},[_c('base-data-table-cards',{attrs:{"title":_vm.$tc('appointment', 2),"footer-props":{
            'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
              ? ''
              : _vm.$t('items_per_page'),
          },"headers":_vm.headers,"headers_small":_vm.headers_small,"data":_vm.items},scopedSlots:_vm._u([{key:"item_customer",fn:function(item){return [_vm._v(_vm._s(item.customer.user.first_name)+" "+_vm._s(item.customer.user.last_name))]}},{key:"item_tattooer",fn:function(item){return [_vm._v(_vm._s(item.tattooer.user.first_name)+" "+_vm._s(item.tattooer.user.last_name))]}},{key:"item_studio",fn:function(item){return [_vm._v(_vm._s(item.studio.user.first_name)+" "+_vm._s(item.studio.user.last_name))]}},{key:"item_date",fn:function(item){return [(item.available_dates.length > 0)?_c('span',[_vm._v(_vm._s(_vm.$browserDetect.isSafari || _vm.$browserDetect.meta.name === "Safari" ? _vm.$d( new Date(item.available_dates[0].date.substring(0, 10)), "date2digits" ) + " " + item.available_dates[0].date.substring(11, 16) : _vm.$d(new Date(item.available_dates[0].date), "short")))]):_c('span',[_vm._v("-")])]}},{key:"item_price",fn:function(item){return [_vm._v(_vm._s(_vm.finalPrice(item)))]}},{key:"item_actions",fn:function(item){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'appointmentView',
                  params: { id: item.id },
                })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$eye")])],1)]}}],null,false,267285510)})],1),_c('v-tab-item',{attrs:{"value":"box"}},[_c('base-data-table-cards',{attrs:{"footer-props":{
            'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
              ? ''
              : _vm.$t('items_per_page'),
          },"title":"Boxs","headers":_vm.headersBoxes,"headers_small":_vm.headersBoxes_small,"data":_vm.itemsBoxes},scopedSlots:_vm._u([{key:"item_box",fn:function(item){return [_vm._v(" "+_vm._s(item.boxes.order_by)+" - "+_vm._s(item.boxes.name)+" ")]}},{key:"item_date",fn:function(item){return [(_vm.$vuetify.breakpoint.smAndDown)?[_c('div',[_vm._v(_vm._s(_vm.$d(new Date(item.start_date), "date2digits")))]),_c('div',[_vm._v(_vm._s(item.start_time)+" - "+_vm._s(item.end_time))])]:_c('div',[_vm._v(" "+_vm._s(_vm.$d(new Date(item.start_date), "date2digits"))+" "+_vm._s(item.start_time)+" - "+_vm._s(item.end_time)+" ")])]}},{key:"item_actions",fn:function(item){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({
                  name: 'boxList',
                  params: { btn: 'day', time: item.start_date },
                })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$eye")])],1)]}}],null,false,1900661636)})],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }